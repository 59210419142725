import { useRecoilState } from "recoil";
import { AuthService } from "../user/user.service";
import { userState } from "../../constants/recoil/user";
import { User } from "../../@types/user/user";
import { TokenService } from "./token.service";

export const useAuthService = () => {
  const [user, setUser] = useRecoilState(userState);

  const getIdentify = () => {
    let TOKEN = sessionStorage.getItem("x-access-token");
    if (TOKEN) {
      if (user.userGuid === "") {
        const userGuid = TokenService.parseJwt(TOKEN).userGuid;
        AuthService.getUser("GUID", userGuid).then((res) => {
          if (res.state === "success") {
            setUser(res.data as User);
          }
        });
      }
    } else {
      setUser(new AuthService());
    }
  };
  return { getIdentify };
};

