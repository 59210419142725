import React, { useState } from "react";
import styled from "styled-components";
import RoundButton from "../../components/button/round-button";
import LoginForm from "./components/login-wrapper";
import { useRecoilState } from "recoil";
import { userState } from "../../constants/recoil/user";
import { useAlert } from "../../components/alert/alert.service";
import { useT } from "../../constants/i18n/useT";
import { EmailAlertModal } from "./components/alert-email/modal";
import { SessionLoginModal } from "./components/session-login/modal";
import LoginFailed from "./components/login-failed/modal";
import { InputService } from "../../service/common/input.service";
import { AuthService } from "../../service/user/user.service";
import { WindowService } from "../../service/common/window.service";
import InputLabel from "../../components/input/input-label/input";

const LoginPage = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useRecoilState(userState);
  const [popAlert, setPopAlert] = useState(false);
  const [popLoginFailed, setPopLoginFailed] = useState(false);
  const [popSessionLogin, setPopSessionLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [isResponse, setIsResponse] = useState(false);

  const t = useT();

  const { setAlert } = useAlert();

  const postLogin = () => {
    setIsResponse(true);
    AuthService.login(id, password)
      .then(res => {
        if (res.state === "success") {
          console.log(res.data);
          console.log(res);
          setUser(res.data);
          WindowService.goWorkstation();
        } else {
          switch (res.errorType) {
            case "Unauthorized":
              setAlert(res.message);
              break;
            case "MailNotVerifiedException":
              setEmail(res.message);
              setPopAlert(true);
              break;
            case "SessionPolicyException":
              if (res.message === "KeepOld") return setPopLoginFailed(true);
              if (res.message === "TerminateOld") return setPopSessionLogin(true);
              break;
            default:
              setAlert(res.message);
          }
        }
      })

      .catch(err => {
        if (err.response.data.state === "fail") {
          setAlert(err.response.data.message);
        } else setAlert(t("common.error", "현재 서버가 원활하지 않습니다. 잠시 후 시도해주세요."));
      })

      .finally(() => setIsResponse(false));
  };

  return (
    <LoginForm>
      <Wrapper>
        <InputLabel
          value={id}
          onChange={e => setId(e.target.value)}
          label={t("user.id", "아이디")}
          placeholder={t("user.please_enter_id", "아이디를 입력하세요") || ""}
        />
        <InputLabel
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          label={t("user.password", "패스워드")}
          placeholder={t("user.please_enter_password", "비밀번호를 입력하세요") || ""}
          onKeyDown={e => InputService.pressEnter(e, postLogin)}
        />
        <div className="login_btn">
          <RoundButton
            onClick={() => postLogin()}
            disabled={isResponse}
            color={isResponse ? "disabled" : "blue"}
            size="full_lg"
          >
            {t("user.sign_in", "로그인")}
          </RoundButton>
        </div>
      </Wrapper>
      {popAlert && (
        <EmailAlertModal userId={id} isOpen={popAlert} setOpen={setPopAlert} message={email} />
      )}
      {popSessionLogin && (
        <SessionLoginModal
          isOpen={popSessionLogin}
          setOpen={setPopSessionLogin}
          userId={id}
          password={password}
        />
      )}
      {popLoginFailed && <LoginFailed isOpen={popLoginFailed} setOpen={setPopLoginFailed} />}
    </LoginForm>
  );
};

const Wrapper = styled.div`
  .login_btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;

export default LoginPage;
