import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ServicePageWrapper from "../../components/wrapper/service-page";
import ProjectNavbar from "../../components/tab/project-nav";
import { LinearProgress } from "@mui/material";

import ProjectCard from "./components/card";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectDelete from "./components/modal/delete/modal";
import ProjectUpdate from "./components/modal/update/modal";
import { ProjectList } from "../../@types/project/response/project-list";
import { ProjectService } from "../../service/project/project.service";

const ProjectListPage = () => {
  const [rawProjects, setRawProjects] = useState(new ProjectList());
  const [projects, setProjects] = useState(new ProjectList());

  const [isLoading, setIsLoading] = useState(true);

  const getList = () => {
    ProjectService.getList()
      .then((res) => {
        if (res.state === "success") {
          setRawProjects(res.data);
          setProjects(res.data);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => getList(), []);
  const location = useLocation();
  const state = location.state as { projectId: number };
  const hash = location.hash;
  const navigate = useNavigate();
  useEffect(() => {
    if (hash === "#update" && state.projectId) {
      handlePopUpdate(state.projectId);
      navigate("/project");
    }
  }, [location]);

  const [popUpdate, setPopUpdate] = useState(false);
  const [popDelete, setPopDelete] = useState(false);
  const [projectId, setProjectId] = useState(0);

  const handlePopUpdate = (projectNum?: number) => {
    setPopUpdate(true);
    setProjectId(projectNum || 0);
  };
  const handlePopDelete = (projectNum?: number) => {
    setPopDelete(true);
    setProjectId(projectNum || 0);
  };

  return (
    <Wrapper>
      <ServicePageWrapper title="Project">
        <ProjectNavbar
          getList={getList}
          rawProjects={rawProjects}
          setProjects={setProjects}
        />
        {isLoading && <LinearProgress />}
        {!isLoading && !!projects.myProjects.length && (
          <>
            <h4>My Project</h4>
            <ProjectListWrapper>
              {projects.myProjects.map((project) => (
                <ProjectCard
                  project={project}
                  key={project.projectId}
                  handlePopUpdate={handlePopUpdate}
                  handlePopDelete={handlePopDelete}
                  editType="all"
                />
              ))}
            </ProjectListWrapper>
          </>
        )}
        {!isLoading && !!projects.sharedAsDirectorProjects.length && (
          <>
            <h4>Shared as Director</h4>
            <ProjectListWrapper>
              {projects.sharedAsDirectorProjects.map((project) => (
                <ProjectCard
                  project={project}
                  key={project.projectId}
                  handlePopUpdate={handlePopUpdate}
                  handlePopDelete={handlePopDelete}
                  editType="updateOnly"
                />
              ))}
            </ProjectListWrapper>
          </>
        )}
        {!isLoading && !!projects.sharedAsMemberProjects.length && (
          <>
            <h4>Shared as Member</h4>
            <ProjectListWrapper>
              {projects.sharedAsMemberProjects.map((project) => (
                <ProjectCard
                  project={project}
                  key={project.projectId}
                  handlePopUpdate={handlePopUpdate}
                  handlePopDelete={handlePopDelete}
                  editType="none"
                />
              ))}
            </ProjectListWrapper>
          </>
        )}
      </ServicePageWrapper>
      {popDelete && (
        <ProjectDelete
          isOpen={popDelete}
          setOpen={setPopDelete}
          projectId={projectId}
          getList={getList}
        />
      )}
      {popUpdate && (
        <ProjectUpdate
          isOpen={popUpdate}
          setOpen={setPopUpdate}
          projectId={projectId}
          getList={getList}
        />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  h2 {
    margin: 30px;
    padding-top: 30px;
    border-top: 2px solid #c5c5c5;
    font-size: 18px;
  }
  h4 {
    margin: 30px;
  }
`;
const ProjectListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export default ProjectListPage;
