import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/nav/Footer";
import BillingPolicy from "./pages/billing/policy/page";
import SignupPage from "./pages/signup/page";
import "./styles/common.css";
import "./styles/guide.css";
import "./styles/style.css";
import "./styles/colors.css";
import PrivateRoute from "./components/wrapper/private-route";
import NotFoundPage from "./components/wrapper/not-found-page";
import ServiceAllDown from "./components/wrapper/service-all-down";
import MainPage from "./pages/main/page";
import PolicyPage from "./pages/policy/page";
import Header from "./components/nav/header";
import LoginPage from "./pages/login/page";
import FindIdPage from "./pages/login/find-id/page";
import FindPasswordPage from "./pages/login/find-pw/page";
import AdminLoginPage from "./pages/login/admin/page";
import DownLoad from "./pages/download/page";
import { AUTH_PATH_NAME, INCLUDE_FOOTER_PATH, PATH_NAME } from "./constants/route";
import WorkstationListPage from "./pages/workstation/page";
import StorageListPage from "./pages/storage/page";
import ProjectListPage from "./pages/project/page";
import UserMainPage from "./pages/mypage/page";
import Guide from "./pages/guide/page";
import { useAuthService } from "./service/common/app.service";
import { WindowService } from "./service/common/window.service";
import { useAlert } from "./components/alert/alert.service";

const App = () => {
  const { pathname } = useLocation();
  const { getIdentify } = useAuthService();

  useEffect(() => {
    WindowService.scrollToTop();
    getIdentify();

    const broadcastChannel = new BroadcastChannel("logout-channel");

    const handleLogout = () => {
      window.location.href = "/";
    };

    broadcastChannel.onmessage = event => {
      if (event.data === "logout") {
        handleLogout();
        WindowService.clearBrowser();
      }
    };

    return () => {
      broadcastChannel.close();
    };
  }, [pathname]);

  const { alertUi } = useAlert();

  return (
    <div>
      {/* <AlertPage /> */}
      {alertUi()}
      <Header />
      <Routes>
        {/* ////////Private Route  */}
        {/* Workstation, Storage, Project, User */}
        <Route
          path={AUTH_PATH_NAME.WORKSTATION}
          element={<PrivateRoute element={<WorkstationListPage />} />}
        />
        <Route
          path={AUTH_PATH_NAME.STORAGE}
          element={<PrivateRoute element={<StorageListPage />} />}
        />
        <Route
          path={AUTH_PATH_NAME.PROJECT}
          element={<PrivateRoute element={<ProjectListPage />} />}
        />
        <Route path={AUTH_PATH_NAME.MYPAGE} element={<PrivateRoute element={<UserMainPage />} />} />
        {/* ////////////////////////// */}
        <Route path={PATH_NAME.MAIN} element={<MainPage />} />
        <Route path={PATH_NAME.LOGIN} element={<LoginPage />} />
        <Route path={PATH_NAME.ADMIN_LOGIN} element={<AdminLoginPage />} />
        <Route path={PATH_NAME.FIND_ID} element={<FindIdPage />} />
        <Route path={PATH_NAME.FIND_PASSWORD} element={<FindPasswordPage />} />
        <Route path={PATH_NAME.POLICY} element={<PolicyPage />} />
        <Route path={PATH_NAME.SIGNUP} element={<SignupPage />} />
        <Route path={PATH_NAME.BILLING_POLICY} element={<BillingPolicy />} />
        <Route path={PATH_NAME.NOT_FOUND} element={<NotFoundPage />} />
        <Route path={PATH_NAME.SERVICE_ALL_DOWN} element={<ServiceAllDown />} />
        <Route path={PATH_NAME.DWONLOAD} element={<DownLoad />} />
        <Route path={PATH_NAME.GUIDE} element={<Guide />} />
      </Routes>

      {INCLUDE_FOOTER_PATH.includes(pathname) && <Footer />}
    </div>
  );
};

export default App;
